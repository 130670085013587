// export const URL = "https://stgapi.pooplogg.com/api/v1/client";
// export const URL2 = "https://stgapi.pooplogg.com/api";
export const BaseUrl = "https://api.pooplogg.com/api/v1/client";
export const URL2 = "https://api.pooplogg.com/api";
export const CONFIG = {
  headers: {
    "Content-Type": "application/json",
    Authorization: JSON.parse(localStorage.getItem("authData")),
  },
};
