import React, { useState } from "react";
import {
  CButton,
  CCard,
  CInput,
  CFormGroup,
  CLabel,
  CRow,
} from "@coreui/react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import swal from "sweetalert";
import { CustomNewButton } from "../../../../containers/helper";
import { BaseUrl } from "../../../../config/config";
import { useDispatch } from "react-redux";
import {
  updateRegEmail,
  updateShowOtp,
} from "../../../../reduxConfig/slice/userSlice";

const PgoRegComps = () => {
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const stepOneValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const stepTwoValidationSchema = Yup.object({
    email: Yup.string().email().required("Email address is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={
          step === 1 ? stepOneValidationSchema : stepTwoValidationSchema
        }
        onSubmit={(values, { resetForm }) => {
          console.log("clicked");
          if (step === 1) {
            setStep(2);
          } else {
            setLoading(true);
            axios
              .post(`${BaseUrl}/register`, {
                phone: values.phone,
                email: values.email,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                type: "PGO",
                platform: "WEB",
              })
              .then((res) => {
                setLoading(false);
                if (res.data.responseCode === 0) {
                  localStorage.setItem(
                    "authData",
                    JSON.stringify(res.data.userId)
                  );
                  sessionStorage.setItem("matchCode", values.email);
                  localStorage.setItem(
                    "accountType",
                    JSON.stringify(res.data.accountType)
                  );
                  dispatch(updateShowOtp(true));
                  dispatch(updateRegEmail(values.email));
                  // window.location.href = "/verify-email";
                } else if (res.data.responseCode > 0) {
                  swal("FAILED", res.data.responseMessage, "danger");
                }
              })
              .catch((err) => err);
          }
          //   axios
          //     .post(`${BaseUrl}/register`, {
          //       phone: values.phone,
          //       email: values.email,
          //       password: values.password,
          //       firstName: values.firstName,
          //       lastName: values.lastName,
          //       type: "PGO",
          //       platform: "WEB",
          //     })
          //     .then((res) => {
          //       if (res.data.responseCode === 0) {
          //         localStorage.setItem(
          //           "authData",
          //           JSON.stringify(res.data.userId)
          //         );
          //         sessionStorage.setItem("matchCode", res.data.validationCode);
          //         localStorage.setItem(
          //           "accountType",
          //           JSON.stringify(res.data.accountType)
          //         );
          //         window.location.href = "/verify-email";
          //       } else if (res.data.responseCode > 0) {
          //         swal("FAILED", res.data.responseMessage, "danger");
          //       }
          //     })
          //     // .then(history.push("/"))
          //     .catch((err) => err);
        }}
      >
        {({ handleSubmit, handleChange, touched, values, errors }) => (
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
                <CFormGroup>
                  <label className="custom-label-new">First Name</label>
                  <input
                    type="text"
                    className="custom-input-new"
                    onChange={handleChange}
                    placeholder="Enter your firstName"
                    name="firstName"
                    value={values.firstName}
                  />
                  <small className="text-danger">
                    {touched.firstName && errors.firstName}
                  </small>
                </CFormGroup>
                <CFormGroup>
                  <label className="custom-label-new">Last Name</label>
                  <input
                    type="text"
                    className="custom-input-new"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {touched.lastName && errors.lastName}
                  </small>
                </CFormGroup>
                <CFormGroup>
                  <label className="custom-label-new">Phone Number</label>
                  <input
                    className="custom-input-new"
                    type="tel"
                    pattern="[0-9]*"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {touched.phone && errors.phone}
                  </small>
                </CFormGroup>
              </>
            )}

            {step === 2 && (
              <>
                <CFormGroup>
                  <label className="custom-label-new">Email</label>
                  <input
                    type="email"
                    className="custom-input-new"
                    placeholder="Enter Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {touched.email && errors.email}
                  </small>
                </CFormGroup>
                <CFormGroup>
                  <label className="custom-label-new">Password</label>
                  <input
                    type="password"
                    className="custom-input-new"
                    placeholder="Enter Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {touched.password && errors.password}
                  </small>
                </CFormGroup>
                <CFormGroup>
                  <label className="custom-label-new">Confirm Password</label>
                  <input
                    type="password"
                    className="custom-input-new"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {touched.confirmPassword && errors.confirmPassword}
                  </small>
                </CFormGroup>
              </>
            )}

            <div
              className="d-flex align-items-center"
              style={{
                marginTop: "40px",
              }}
            >
              <CustomNewButton
                type="submit"
                disabled={loading}
                text={loading ? "Loading..." : step === 1 ? "Next" : "Register"}
              ></CustomNewButton>

              <div
                style={{
                  width: "140px",
                  marginLeft: "120px",
                  textAlign: "end",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Step {step} of 2
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PgoRegComps;

//   const validationSchema = Yup.object({
//     firstName: Yup.string().required("first name is required"),
//     lastName: Yup.string().required("last name is required"),
//     email: Yup.string().email().required("email address is required"),
//     password: Yup.string().required("password is required"),
//     phone: Yup.string()
//       .matches(phoneRegExp, "Phone number is not valid")
//       .required("phone number is required"),
//     confirmPassword: Yup.string().oneOf(
//       [Yup.ref("password")],
//       "Password did not match"
//     ),
//   });
