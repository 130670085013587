import React, { useState } from "react";
import { HorizontalBar } from "../../../containers/helper";
import PgoRegComps from "./components/pgo_reg";
import P4bRegComps from "./components/p4b_reg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OTPInput from "otp-input-react";
import axios from "axios";
import swal from "sweetalert";
import {
  updateRegType,
  updateShowOtp,
} from "../../../reduxConfig/slice/userSlice";
import { CONFIG, BaseUrl } from "../../../config/config";
// import OtpInput from "./components/otp_comps";

const RegisterNew = () => {
  // const [regType, setRegType] = useState("PGO");
  const { profile, regType, isShowOtp, regEmail } = useSelector(
    (state) => state.userSlice
  );
  // const { isPgo, isShowOtp } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  console.log("isGo", regType, "isShow", isShowOtp, profile);

  const typeArr = [
    {
      title: "Individual",
      icon: "/pgo_logo.svg",
      view: "",
      name: "PHome",
    },
    {
      title: "Business",
      icon: "/biz_logo.svg",
      view: "",
      name: "P4B",
    },
  ];

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState("");

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: "1000",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <img src="/assets/pre-loading.gif" alt="" height="50px" width="50px" />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          style={{
            width: "40%",
            padding: "20px 80px",
          }}
        >
          <img src="/new_logo.png" alt="new-logo" height="100px" />
          <HorizontalBar />
          <div
            style={{
              fontSize: "25px",
              fontWeight: "700",
              color: "#056897",
            }}
          >
            Register
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#858484",
            }}
          >
            Welcome to Pooplogg, please choose your account type below
          </div>
          <div
            style={{ display: "flex", marginTop: "50px", marginBottom: "40px" }}
          >
            {typeArr.map((item, id) => (
              <div
                key={id}
                onClick={() => {
                  dispatch(updateRegType(item.name));
                  dispatch(updateShowOtp(false));
                  // setRegType(item.name);
                }}
                style={{
                  cursor: "pointer",
                  width: "50%",
                  marginRight: "20px",
                  padding: "15px 20px",
                  borderRadius: "3px",
                  border: regType === item.name ? "1px solid #134468" : "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor:
                    regType === item.name ? "#C1FFDB" : "transparent",
                }}
              >
                <img src={item.icon} alt="" />
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {item.title}
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1.5px solid #134468",
                    fontWeight: "700",
                    backgroundColor:
                      regType === item.name ? "#134468" : "transparent",
                    color: regType !== item.name ? "#134468" : "#fff",
                  }}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
          {isShowOtp ? (
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                OTP
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: 40,
                  color: "#938F8F",
                }}
              >
                Please enter the OTP Sent to your email
              </div>
              <OTPInput
                value={otp}
                inputStyles={{
                  padding: "10px",
                  height: "50px",
                  width: "50px",
                }}
                onChange={(e) => {
                  setOtp(e);
                  if (e.length === 6) {
                    console.log("log sent");
                    setLoading(true);
                    axios
                      .post(
                        `${BaseUrl}/verifyEmailCode`,
                        {
                          email: regEmail,
                          code: `${e}`,
                        },
                        CONFIG
                      )
                      .then((res) => {
                        console.log(res);
                        setLoading(false);
                        if (res.data.responseCode === 0) {
                          window.location.href = "/";
                        } else if (res.data.responseCode > 0) {
                          swal("SORRY", res.data.responseMessage, "warning");
                        }
                      })
                      .catch((err) => err);
                  }
                }}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                // secure
              />
            </div>
          ) : regType === "PHome" ? (
            <PgoRegComps />
          ) : (
            <P4bRegComps />
          )}
          {!isShowOtp && (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "24px",
                color: "#134468",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  fontWeight: "600",
                  color: "#134468",
                }}
              >
                Login
              </Link>
            </div>
          )}{" "}
        </div>

        {regType === "PHome" ? (
          <div
            style={{
              width: "60%",
              backgroundColor: "#134468",
              padding: "42px",
              backgroundImage: "url(/plg_bg1.png)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div style={headerText}>Pooplogg for Individual</div>
            <div style={subHeaderText}>
              By engaging with our technological platform you are enabled to
              order, schedule evacuations, request for any services around,
              waste, sewage and portable water
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "60%",
              margin: "20px",
              // backgroundColor: "#134468",
              padding: "42px",
              position: "relative",
              backgroundImage: "url(/plg_bg2.png)",
              backgroundSize: "cover",
            }}
          >
            <div style={headerText}>Pooplogg for Business</div>
            <div style={subHeaderText}>
              By engaging with our technological platform you are enabled to
              order, schedule evacuations, request for any services around,
              waste, sewage and portable water
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default RegisterNew;

const headerText = {
  fontSize: "24px",
  fontWeight: "700",
  color: "#fff",
};

const subHeaderText = {
  fontSize: "14px",
  fontWeight: "500",
  color: "#fff",
};
